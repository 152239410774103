<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE INFORMES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-paste fa-3x"></i> <br>
                            <span class="lg-numero">{{listaInformes.length}}</span>
                            <br>
                            <span class="text-muted">Informes generados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Informes</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('004-14001-INF','c') == 1" md="6" class="my-2">
                            <b-button block @click="modalRegistrarInforme = true" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo informe
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Informes registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaInformes" :fields="campoInformes" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-INF','u') == 1" @click="abrirModalActualizarInforme(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button :disabled="disabled" @click="generarDocumento(param)" class="mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'">
                                        <i class="fas fa-download my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-INF','d') == 1" @click="eliminarInforme(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                       <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(tipoInformacion)="param">
                                    {{param.item.tipoInformacion | truncate(35)}}
                                </template>
                                <template #cell(nombreDocumento)="param">
                                    {{param.item.nombreDocumento | truncate(30)}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalRegistrarInforme">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo informe </span>
            </h6>
            <CButtonClose @click="modalRegistrarInforme = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarInforme)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="evaluacion" rules="required" v-slot="{errors}">
                            <b-form-group label="Evaluación:" class="mb-2">
                                <v-select :reduce="comboEvaluacion =>comboEvaluacion.idEvaluacion" label="fecha" @input="listarEvaluacionGestiones()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInforme.idEvaluacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInforme.idEvaluacion" :options="comboEvaluacion" @search:blur="blurEvaluacion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Evaluación 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="gestion" rules="required" v-slot="{errors}">
                            <b-form-group label="Gestión de aspectos:" class="mb-2">
                                <v-select :reduce="comboGestion =>comboGestion.idEvaluacionDetalleGestion" label="fechaGestion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInforme.idEvaluacionDetalleGestion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInforme.idEvaluacionDetalleGestion" :options="comboGestion" @search:blur="blurGestion">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="fecha de creación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de creación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosInforme.fechaInforme"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarInforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="primario" :show.sync="modalActualizarInforme">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar informe </span>
            </h6>
            <CButtonClose @click="modalActualizarInforme = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarInforme)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="evaluacion" rules="required" v-slot="{errors}">
                            <b-form-group label="Evaluación:" class="mb-2">
                                <v-select :reduce="comboEvaluacion =>comboEvaluacion.idEvaluacion" label="fecha" @input="listarEvaluacionGestiones()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarInforme.idEvaluacion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarInforme.idEvaluacion" :options="comboEvaluacion" @search:blur="blurActualizarEvaluacion">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Evaluación 14001'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="gestion" rules="required" v-slot="{errors}">
                            <b-form-group label="Gestión de aspectos:" class="mb-2">
                                <v-select :reduce="comboGestion =>comboGestion.idEvaluacionDetalleGestion" label="fechaGestion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarInforme.idEvaluacionDetalleGestion , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarInforme.idEvaluacionDetalleGestion" :options="comboGestion" @search:blur="blurActualizarGestion">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="fecha de creación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de creación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarInforme.fechaInforme"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarInforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <div id="graficoAspectos" ref="graficoAspectos" style="width: 850px;height:400px;display:none; "></div>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import expressions from 'angular-expressions';
import assign from "lodash.assign";

import * as echarts from 'echarts';
var myChart;

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    components: {

    },
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoInformes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "proyecto",
                    label: "Proyecto",
                    class: "text-center",
                },
                {
                    key: "codigoInforme",
                    label: "Código",
                    class: "text-center",
                }, {
                    key: "tipoInformacion",
                    label: "Tipo de Información",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha de Publicación",
                    class: "text-center",
                }, {
                    key: "nombreDocumentacion",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaInformes: [],

            modalRegistrarInforme: false,
            modalActualizarInforme: false,

            datosInforme: {
                idEvaluacion: null,
                idEvaluacionDetalleGestion: null,
                fechaInforme: moment().format('YYYY-MM-DD')
            },
            datosActualizarInforme: {
                idInforme: '',
                idEvaluacion: null,
                idEvaluacionDetalleGestion: null,
                fechaInforme: ''
            },
            comboEvaluacion: [],
            comboGestion: [],
            datosSession: {
                idCliente: ''
            },
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        generarDocumento(param) {
            let me = this;
            me.disabled = true;

            if (myChart != null && myChart != "" && myChart != undefined) {
                myChart.dispose();
            }

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/generar-informe-evaluacion-aspectos-ambientales", {
                        params: {
                            //idCliente: me.datosSession.idCliente,
                            idInforme: param.item.idInforme,
                            idEvaluacionDetalleGestion: param.item.idEvaluacionDetalleGestion
                        },
                    }
                )
                .then(function (response) {

                    let dataSets = [];
                    for (const i in response.data.dataEvaluacionAspecto) {
                        if (response.data.dataEvaluacionAspecto[i].prioridad != null || response.data.dataEvaluacionAspecto[i].frecuencia != null) {
                            dataSets.push({
                                name: response.data.dataEvaluacionAspecto[i].aspecto,
                                value: Math.round((response.data.dataEvaluacionAspecto[i].frecuencia +
                                    response.data.dataEvaluacionAspecto[i].importancia +
                                    response.data.dataEvaluacionAspecto[i].capacidad) / 8 * 100),
                            })
                        }
                    }
                    dataSets.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                    myChart = echarts.init(document.getElementById('graficoAspectos'));

                    var option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: function (params, ticket, callback) {
                                var res = '';
                                for (const i in params) {
                                    res += params[i].name + ': ' + params[i].value + ' %';
                                }
                                callback(ticket, res);
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            min: 0,
                            max: 100,
                            animation: false,
                        },
                        yAxis: {
                            type: 'category',
                            animation: false,
                            data: (function () {
                                var res = [];
                                for (const i in dataSets) {
                                    res.push(dataSets[i].name)
                                }
                                return res;
                            })()
                        },
                        series: [{
                            /* name: 'Riesgo', */
                            type: 'bar',
                            animation: false,
                            data: dataSets
                        }, ]
                    };

                    myChart.setOption(option);

                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-evaluacion-aspectos-ambientales.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [430, 250];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data.dataEvaluacionAspecto[0].urlLogo,
                            fecha: response.data.dataEvaluacionAspecto[0].fecha,
                            listaEvaluacionAspectos: response.data.dataEvaluacionAspecto,
                            listaMedidas: response.data.dataMedidas,
                            fechaGestion: response.data.dataMedidas[0].fechaGestion,
                            grafico: myChart.getDataURL()
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "evaluacion-de-aspectos.docx");
                        //me.disabled = false;
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarInformes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-informes-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaInformes = response.data;
                    me.totalRows = me.listaInformes.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEvaluaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboEvaluacion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEvaluacionGestiones() {
            let me = this;
            (me.datosInforme.idEvaluacionDetalleGestion = null),
            (me.datosActualizarInforme.idEvaluacionDetalleGestion = null),
            (me.comboGestion = []),

            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-gestiones-evaluacion-sgma", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: me.datosInforme.idEvaluacion ? me.datosInforme.idEvaluacion : me.datosActualizarInforme.idEvaluacion,
                        },
                    }
                )
                .then(function (response) {
                    me.comboGestion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarInforme() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-informe-sgma", {
                        datosInforme: me.datosInforme,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInformes();
                    me.modalRegistrarInforme = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarInforme() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-informe-sgma", {
                        datosInforme: me.datosActualizarInforme,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarInformes();
                    me.modalActualizarInforme = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        abrirModalActualizarInforme(param) {

            this.datosActualizarInforme.idInforme = param.item.idInforme;
            this.datosActualizarInforme.fechaInforme = param.item.fechaInforme;
            this.datosActualizarInforme.idEvaluacion = param.item.idEvaluacion;
            this.listarEvaluacionGestiones();
            this.datosActualizarInforme.idEvaluacionDetalleGestion = param.item.idEvaluacionDetalleGestion;
            this.modalActualizarInforme = true;
        },

        blurEvaluacion() {
            this.computedForm.refs.evaluacion.validate();
        },
        blurGestion() {
            this.computedForm.refs.gestion.validate();
        },
        blurActualizarEvaluacion() {
            this.computedActualizarForm.refs.evaluacion.validate();
        },
        blurActualizarGestion() {
            this.computedActualizarForm.refs.gestion.validate();
        },
        eliminarInforme(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el informe?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-informe-sgma", {
                            idInforme: param.item.idInforme,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarInformes();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        resetModalRegistrarInforme() {
            this.datosInforme.idEvaluacion = null;
            this.datosInforme.idEvaluacionDetalleGestion = null;
            this.datosInforme.fechaInforme = moment().format('YYYY-MM-DD');
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },
        resetModalActualizarInforme() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarInforme: function (val) {
            if (val == false) {
                this.resetModalRegistrarInforme();
            }
        },
        modalActualizarInforme: function (val) {
            if (val == false) {
                this.resetModalActualizarInforme();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarInformes();
            this.listarEvaluaciones();
        }
    }

}
</script>
